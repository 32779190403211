import './App.css';
import React, { useState } from "react";
import { HashRouter as Router, Routes,Route } from 'react-router-dom';
import Pengumuman from './pages/pengumuman';
import Peserta from './pages/peserta';
import { Layout } from './layout';
import Formpage from './pages/fomrpage';
import Login from './pages/loginpage';
import { RequireAuth } from 'react-auth-kit';
import Newspage from './pages/newspage';

function App() {
  const [dataType, setDataType] = useState('');
  return (
    
    <Router>
      <Routes>
        <Route element={<Layout dataType={dataType}/>}>
          <Route path="/pengumuman" element={<RequireAuth loginPath='/login'> <Newspage dataType={dataType}/> </RequireAuth>}/>
          <Route path="/" element={<RequireAuth loginPath='/login'>
             <Pengumuman dataType={dataType}/>
           </RequireAuth>}></Route>
          <Route path="/peserta" element={<RequireAuth loginPath='/login'>
            <Peserta/>
          </RequireAuth>}/>
          <Route path="/formpage" element={<RequireAuth loginPath='/login'>
          <Formpage dataType={dataType}/>
          </RequireAuth>}/>
        </Route>
        <Route path="/login" element={<Login setDataType={setDataType}/>}/>
      </Routes>
    </Router>
  );
}

export default App;
